<template>
  <div class="drawer-result">
    <DefaultResult/>
  </div>
</template>
<script>
export default ({
  components: {
    DefaultResult: () => import('@/views/application/drawer/component/DefaultResult.vue')
  }
})

</script>

<style scoped lang="scss">
.drawer-result {
  height: 100%;
}
</style>